// Here you can add other styles
// Here you can add other styles
h4,
h5,
h6 {
  margin-bottom: 0;
}

.bg-dark {
  --cui-bg-opacity: 0;
  background-color: black !important;
}

.btn-dark {
  --cui-btn-bg: #000 !important;
  --cui-btn-border-color: #000 !important;
}

.nav {
  --cui-nav-link-padding-x: 1rem;
  --cui-nav-link-padding-y: 0.5rem;
  --cui-nav-link-font-weight: ;
  --cui-nav-link-color: var(--cui-link-color);
  --cui-nav-link-hover-color: var(--cui-link-hover-color);
  --cui-nav-link-disabled-color: #8a93a2;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-underline {
  border-bottom: 2px solid var(--cui-nav-underline-border-color, #c4c9d0);
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  color: var(--cui-nav-underline-link-active-color, #000);
}

.nav-underline .nav-link.active,
.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover,
.nav-underline .show > .nav-link {
  border-color: var(--cui-nav-underline-link-active-border-color, #000);
}

.nav-underline .nav-link {
  border-bottom: 2px solid transparent;
  border-bottom-color: transparent;
  color: var(--cui-nav-underline-link-color, #000);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: #9da5b1;
  border-color: #9da5b1;
}

.form-text {
  margin-top: 0.175rem;
  font-size: 1rem;
  color: #9da5b1;
}

.btn:hover {
  background-color: #525252;
  border-color: #525252;
}

.form-label {
  font-weight: 500;
  font-size: 18px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #b1b7c1);
}

.form-select:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #b1b7c1);
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0em;
  margin-right: 0.5em;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #000);
  border-color: var(--cui-form-check-input-checked-border-color, #000);
}

.accordion-button:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #b1b7c1);
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #b1b7c1);
}

.app-header-title {
  background-color: #e8e8e8;
  font-weight: 500;
}

.app-table-header {
  text-align: start;
}

.app-table-content-list {
  text-align: start;
  vertical-align: middle;
}

.table-badge-status-content {
  padding: 10px 20px;
  font-size: 12px;
}

.cell-table-out-of-stock {
  color: #9e9e9e;
}

.page-link.active,
.active > .page-link {
  background-color: #000;
  border-color: #000;
}

.dropdown-toggle-table-menu {
  border: none;
  box-shadow: none;
}

// upload image container
.upload_dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: border 0.24s ease-in-out;
  outline: 2px dashed #9e9e9e;
  border: 2px dashed #000;
  border-radius: 0.5rem;
}

.btn-upload {
  color: #fff;
  background-color: #000;
  padding: 10px 15px;
  border-color: #000;
  border-radius: 0.5rem;
}

.btn-upload:hover,
.btn-upload:focus {
  background-color: #525252;
  border-color: #525252;
  color: #fff;
}

.upload_gallery {
  width: calc(33.333% - (2rem / 3));
  object-fit: contain;
}

// customer details
.customer-details-address-list {
  margin-bottom: 1rem;
  color: #000;
  padding: 8px 12px;
  border: 2px solid #e8e8e8;
  border-radius: 0.5rem;
}

.customer-details-main-shipping-address {
  margin-bottom: 0rem;
  font-style: italic;
  font-size: 14px;
  text-align: end;
}

.customer-details-tab-container {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  max-height: 39rem;
  overflow: auto;
}

// chat details
.chat_main_container {
  height: calc(100vh - 234px);
}
.chat_sidebar {
  width: 22rem;
  //   height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.chat_room {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.chat_room:hover {
  background-color: lightgrey;
}

.chat_room_active {
  background-color: lightskyblue;
}

.chat_room_info {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
  flex: 1;
}

.chat_room_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_room_content_preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_room_header_text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
